.dropdown-main {
  width: 300px !important;
}

.logo-container {
  display: inline-block;
  height: 100%;
  left: 5%;
}
.logo-img {
  width: 55px;
  height: 55px;
  vertical-align: middle;
  margin-top: 3px;
}
@media only screen and (max-width: 600px) {
  .logo-container {
    display: inline-block;
    height: 100%;
  }
  .logo-img {
    width: 50px;
    height: 50px;
    vertical-align: middle;
    margin-top: 3px;
  }
}
