.Signup {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

.Signup-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
}

.mybutton {
    border-radius: 10px;
    border-color: red;
    background-color: white;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
}

@media (min-width: 650px ) {
    .Signup {
        background-position: center center;
    }

    .Signup-content {
        align-items: flex-end;
        justify-content: center;
    }
}