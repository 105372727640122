
.Login {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    z-index: -1;
}

.Login-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.loginCard {
    float: right;
}

.mybutton {
    border-radius: 10px;
    border-color: red;
    background-color: white;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.loginCard {
    top: 0px;
    bottom: 0px;
    margin-bottom: auto;
    margin-top: auto;
    }

@media (min-width: 650px ) {
   
    #toast-container {
        right: 50%;
        transform: translateX(50%) translateY(50%);
    }

    .Login-content {
        justify-content: center;
    }
}