
nav{
    padding: 0 10px 0 10px;
}

nav .right{
    position: absolute;
    top: 0px;
    right: 0px;
}

.nav-wrapper form{
    margin: auto;
    width: 50%;
}

.nav-wrapper .input-field{
    padding-top: 7px;
}

.nav-wrapper form #search{
    height: 50px;
}

.selectWrapper{
    border-radius: 10px;
    display:inline-block;
    overflow:hidden;
  }
  .selectBox{
    width: 150px;
    border:0px;
    outline:none;
  }

