.image { 
  position: relative; 
  width: 100%; /* for IE 6 */
}

.mycontent { 
  width: 100%;
  position: absolute; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}