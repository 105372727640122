@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @font-face {
      font-family: 'Nexa';
      src: url('Assets/fonts/Nexa/Nexa-Book.ttf') format('truetype');
  }
  @font-face {
      font-family: 'Proxima';
      src: url('Assets/fonts/Proxima/Proxima.otf') format('opentype');
  }
  @font-face {
      font-family: 'Proxima-Regular';
      src: url('Assets/fonts/Proxima/Proxima-Regular.otf') format('opentype');
  }
  @font-face {
      font-family: 'Nexa-Regular';
      src: url('Assets/fonts/Nexa/NexaRegular.ttf') format('truetype');
  }
  @font-face {
      font-family: 'Nexa-Bold';
      src: url('Assets/fonts/Nexa/Nexa-Bold.ttf') format('truetype');
  }
  @font-face {
      font-family: 'Nexa-Black';
      src: url('Assets/fonts/Nexa/NexaBlack.ttf') format('truetype');
  }
  h6 {
      @apply font-nexabold
  }
  h1 {
      @apply font-nexabold text-3xl
  }
}

*:focus {
  outline: none !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ADB9CA !important;
  opacity: 1 !important;
  ;
  /* Firefox */
}
