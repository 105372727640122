.postercontenttop { 
    position: absolute; 
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  .postercontentbottom {
    position: absolute; 
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
