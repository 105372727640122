.pagination {
    padding: 40px;
    float: right;
    display: inline-block;
  }
  
  .pagination a {
    color: black;
    float: left;
    padding: 8px 16px;
    text-decoration: none;
  }
  
  .pagination a.active {
    background-color: #fe0000;
    color: white;
  }
  
  .pagination a:hover:not(.active) {background-color: #ddd;}